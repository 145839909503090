import {
  CalculatePaths,
  MaintenancePlanPaymentFlow,
  PaymentMethod,
  PaymentWorkflowOption,
  PricebookTaxRateDto,
  bzExpect,
  calculateInvoiceTotals,
  getPaymentWorkflowOptionFromPaymentMethod,
  isInvoiceFullyPaid,
  isProcessingFinalPayment,
  noOp,
  paymentRecordsToInvoicePayments,
  usCentsToUsd,
} from '@breezy/shared'
import {
  faEdit,
  faEllipsis,
  faEnvelope,
  faSackDollar,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PaymentWorkflowOptionConfig } from 'src/components/PaymentWorkflow/PaymentWorkflowWizard'
import { useCompanyHiddenPaymentMethods } from 'src/hooks/useCompanyHiddenPaymentMethods'
import { useSubscription } from 'urql'
import { ActionBar } from '../../adam-components/ActionBar/ActionBar'
import {
  MobileActionBar,
  MobileActionBarButton,
} from '../../adam-components/ActionBar/MobileActionBar'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageContainer } from '../../adam-components/OnsitePage/OnsitePageContainer'
import { OnsitePageLoader } from '../../adam-components/OnsitePage/OnsitePageLoader'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import {
  OnsiteEmbeddedContext,
  useGoBack,
} from '../../adam-components/OnsitePage/onsitePageUtils'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import { LoanPromoShortDisclaimer } from '../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import FinancingSection from '../../components/FinancingSection/FinancingSection'
import { BaseLoadingSpinner } from '../../components/LoadingSpinner'
import { usePaymentWorkflowWizard } from '../../components/PaymentWorkflow/hooks/usePaymentWorkflowWizard'
import Switch from '../../elements/Switch/Switch'
import useIsMobile from '../../hooks/useIsMobile'
import {
  useQuickbooksDesktopEnabled,
  useQuickbooksOnlineEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useModalState,
  useQueryParamFlag,
  useStrictContext,
} from '../../utils/react-utils'
import { InvoiceEditView } from './InvoiceEditView'
import { INVOICE_DATA_SUBSCRIPTION } from './Invoices.gql'
import { InvoiceAccountingSection } from './components/InvoiceAccountingSection'
import {
  InvoiceActionsModal,
  useSendInvoiceEmail,
} from './components/InvoiceActionsModal'
import { InvoiceContent } from './components/InvoiceContent'
import { InvoiceHistorySection } from './components/InvoiceHistory'
import {
  InvoiceInfoContext,
  InvoiceInfoContextType,
} from './components/InvoiceInfoModalContent'
import { InvoicePageContainer } from './components/InvoicePageContainer'
import { PresentableInvoiceContent } from './components/PresentableInvoiceContent'
import { useProcessingPaymentsModal } from './components/ProcessingPaymentsModal'
import { QboSection } from './components/QboSection'
import { SignatureForm } from './components/SignatureForm'
import { SignatureSection } from './components/SignatureSection'
import { TaxAndDynamicSection } from './components/TaxAndDynamicSection'
import {
  FetchedInvoice,
  INVOICE_STATUS_DISPLAY_INFO,
  InvoiceBillingAddress,
  InvoiceContext,
  InvoiceDataContext,
  InvoiceEditDataContext,
  InvoiceInfo,
  InvoiceInfoFormData,
  getAppointmentLinkFromInvoice,
  getInvoiceV2StatusDisplayInfo,
  getProcessingPayment,
  mapInvoiceToAbidgedInvoiceMetadata,
  useDiscountsFromInvoiceQuery,
  useInvoiceHistory,
  useInvoiceQboInfo,
  useIsInvoiceFinanceable,
  useLineItemsFromInvoiceQuery,
  useRelevantInvoiceData,
  useRelevantInvoiceEditData,
  useSaveInvoiceSignature,
  useTaxRateFromInvoiceQuery,
  useWriteInvoiceEvent,
} from './invoiceUtils'

type EditViewProps = {
  invoice: FetchedInvoice
}

const EditView = React.memo<EditViewProps>(({ invoice }) => {
  const {
    data: relevantInvoiceEditData,
    hasLoaded: relevantEditDataHasLoaded,
  } = useRelevantInvoiceEditData(invoice.accountGuid, invoice.jobLink?.jobGuid)

  const defaultInfoFormData = useMemo<InvoiceInfoFormData>(() => {
    let billingAddress: InvoiceBillingAddress = {
      type: 'same_as_service',
    }
    if (invoice.billingAddress) {
      if (
        invoice.account.mailingAddressGuid ===
        invoice.billingAddress.addressGuid
      ) {
        billingAddress = {
          type: 'account_billing_address',
          accountBillingAddressGuid: invoice.billingAddress.addressGuid,
        }
      } else if (
        invoice.billingAddress.addressGuid ===
        invoice.serviceAddress.addressGuid
      ) {
        billingAddress = {
          type: 'same_as_service',
        }
      } else {
        billingAddress = {
          type: 'adhoc',
          adHocAddress: invoice.billingAddress,
        }
      }
    }

    return {
      ...invoice,
      customerPurchaseOrderNumber: invoice.customerPurchaseOrderNumber ?? '',
      serviceLocationGuid: invoice.locationLink?.locationGuid ?? '',
      billingAddress,
    }
  }, [invoice])

  const defaultTaxRate = useMemo<PricebookTaxRateDto>(
    () => ({
      name: invoice.taxRateName,
      rate: invoice.taxRate,
      pricebookTaxRateGuid: invoice.taxRateGuid,
    }),
    [invoice.taxRate, invoice.taxRateGuid, invoice.taxRateName],
  )

  const lineItems = useLineItemsFromInvoiceQuery(invoice)
  const discounts = useDiscountsFromInvoiceQuery(invoice)

  const existingPayments = useMemo(
    () => paymentRecordsToInvoicePayments(invoice.paymentRecords),
    [invoice.paymentRecords],
  )

  if (!relevantEditDataHasLoaded) {
    return <OnsitePageLoader />
  }

  if (!relevantInvoiceEditData || !defaultInfoFormData) {
    return (
      <OnsitePageContainer title="Error">
        <div className="text-center">Account not found</div>
      </OnsitePageContainer>
    )
  }

  return (
    <InvoiceEditDataContext.Provider value={relevantInvoiceEditData}>
      <InvoiceEditView
        status={invoice.status}
        defaultTaxRate={defaultTaxRate}
        defaultDynamicPricingType={invoice.dynamicPricingType}
        defaultMessageHtml={invoice.messageHtml}
        defaultInfoFormData={defaultInfoFormData}
        defaultLineItems={lineItems}
        defaultDiscounts={discounts}
        issuedAt={invoice.issuedAt}
        existingPayments={existingPayments}
      />
    </InvoiceEditDataContext.Provider>
  )
})

type InvoiceOverviewProps = {
  invoice: FetchedInvoice
}

const InvoiceOverview = React.memo<InvoiceOverviewProps>(({ invoice }) => {
  const isMobile = useIsMobile()
  const goBack = useGoBack()
  const navigate = useNavigate()

  const [, openEditMode] = useQueryParamFlag('edit')
  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
  const isQuickbooksDesktopEnabled = useQuickbooksDesktopEnabled()
  const message = useMessage()
  const { embedded } = useContext(OnsiteEmbeddedContext)
  const { invoiceGuid, companyName, accountGuid, jobGuid } =
    useStrictContext(InvoiceContext)
  const [presentMode, openPresentMode] = useQueryParamFlag('present')

  const lineItems = useLineItemsFromInvoiceQuery(invoice)
  const pageContainerRef = useRef<HTMLDivElement>(null)

  const discounts = useDiscountsFromInvoiceQuery(invoice)

  const taxRate = useTaxRateFromInvoiceQuery(invoice)

  const [actionsModalOpen, openActionsModal, closeActionsModal] =
    useModalState()

  const writeInvoiceEvent = useWriteInvoiceEvent()

  const onPresentToCustomer = useCallback(() => {
    // we don't want to block the ui so we fire and forget
    writeInvoiceEvent({
      type: 'PRESENTED',
      invoiceGuid,
    })
    openPresentMode()
  }, [invoiceGuid, openPresentMode, writeInvoiceEvent])

  const info = useMemo<InvoiceInfo>(() => {
    return {
      ...invoice,
      customerPurchaseOrderNumber: invoice.customerPurchaseOrderNumber ?? '',
      billingAddress: invoice.billingAddress
        ? {
            type: 'adhoc',
            adHocAddress: invoice.billingAddress,
          }
        : {
            type: 'same_as_service',
          },
      resolvedBillingAddress: invoice.billingAddress,
      serviceContact:
        invoice.jobLink?.job.pointOfContact ??
        invoice.account.accountContacts[0].contact,
      // I know based on business logic that this will always be defined
      serviceLocationGuid: invoice.locationLink?.locationGuid ?? '',
    }
  }, [invoice])

  const invoiceTotals = useMemo(
    () =>
      calculateInvoiceTotals(
        lineItems,
        invoice.taxRate,
        discounts,
        paymentRecordsToInvoicePayments(invoice.paymentRecords),
        invoice.dynamicPricingType,
      ),
    [
      discounts,
      invoice.dynamicPricingType,
      invoice.paymentRecords,
      invoice.taxRate,
      lineItems,
    ],
  )

  const history = useInvoiceHistory(invoice)
  const existingPayments = useMemo(
    () => paymentRecordsToInvoicePayments(invoice.paymentRecords),
    [invoice.paymentRecords],
  )

  const paymentInvoice = mapInvoiceToAbidgedInvoiceMetadata(
    invoiceGuid,
    invoice,
  )

  const onPaymentSuccess = useCallback(() => {
    if (presentMode) {
      goBack()
    }
  }, [goBack, presentMode])

  const { hiddenPaymentMethods } = useCompanyHiddenPaymentMethods()

  const hiddenPaymentMethodsForMaintenancePlans: PaymentMethod[] | undefined =
    useMemo(() => {
      if (!hiddenPaymentMethods) {
        return []
      }

      if (!invoice.maintenancePlanLink?.maintenancePlan) {
        return hiddenPaymentMethods
      }

      if (
        invoice.maintenancePlanLink.maintenancePlan.paymentFlow ===
        MaintenancePlanPaymentFlow.AUTO
      ) {
        const cardIdx = hiddenPaymentMethods.findIndex(
          paymentMethod => paymentMethod === PaymentMethod.CARD,
        )
        if (cardIdx < 0) {
          return hiddenPaymentMethods
        }

        return hiddenPaymentMethods.slice(cardIdx, 1)
      }

      return hiddenPaymentMethods
    }, [hiddenPaymentMethods, invoice.maintenancePlanLink?.maintenancePlan])

  const hiddenPaymentWorkflowOptions: PaymentWorkflowOption[] | undefined =
    useMemo(() => {
      if (!invoice.maintenancePlanLink?.maintenancePlan) {
        return hiddenPaymentMethods?.map(paymentMethod =>
          getPaymentWorkflowOptionFromPaymentMethod(paymentMethod),
        )
      }

      return hiddenPaymentMethodsForMaintenancePlans?.map(paymentMethod =>
        getPaymentWorkflowOptionFromPaymentMethod(paymentMethod),
      )
    }, [
      hiddenPaymentMethods,
      hiddenPaymentMethodsForMaintenancePlans,
      invoice.maintenancePlanLink?.maintenancePlan,
    ])

  const paymentWorkflowDisabledOptions: PaymentWorkflowOptionConfig['disabledOptions'] =
    useMemo(() => {
      const options: PaymentWorkflowOptionConfig['disabledOptions'] = []

      if (!hiddenPaymentWorkflowOptions) {
        return options
      }

      if (
        invoice.maintenancePlanLink?.maintenancePlan &&
        invoice.maintenancePlanLink.maintenancePlan.paymentFlow ===
          MaintenancePlanPaymentFlow.MANUAL
      ) {
        if (hiddenPaymentWorkflowOptions.includes('Credit Card'))
          options.push({ option: 'Payment Link', hide: true })
      }

      return options
    }, [
      hiddenPaymentWorkflowOptions,
      invoice.maintenancePlanLink?.maintenancePlan,
    ])

  const { showPaymentWorkflowWizard, paymentWorkflowWizard } =
    usePaymentWorkflowWizard({
      invoice: paymentInvoice,
      invoiceAmountDueUsd: usCentsToUsd(invoiceTotals.dueUsc),
      editableAmount: !invoice.maintenancePlanLink,
      onPaymentSuccess,
      hiddenOptions: hiddenPaymentWorkflowOptions,
      disabledOptions: paymentWorkflowDisabledOptions,
    })

  const { showProcessingPaymentsModal, processingPaymentsModal } =
    useProcessingPaymentsModal()

  const onCollectPayment = useCallback(() => {
    const processingPayment = getProcessingPayment(invoice)
    if (isProcessingFinalPayment(invoiceTotals) && processingPayment) {
      showProcessingPaymentsModal(processingPayment)
    } else {
      showPaymentWorkflowWizard()
    }
  }, [
    invoice,
    invoiceTotals,
    showPaymentWorkflowWizard,
    showProcessingPaymentsModal,
  ])

  const [signatureMode, openSignatureMode] = useQueryParamFlag('sign')

  const { isStale: isQboStale, qboSyncedAt } = useInvoiceQboInfo(invoice)

  const [saveSignature, isSavingSignature] = useSaveInvoiceSignature()

  const onSignatureSubmit = useCallback(
    async (signaturePNG: string) => {
      await saveSignature(signaturePNG)
      await writeInvoiceEvent({
        type: 'SIGNED',
        invoiceGuid,
      })
      goBack()
      message.success('Signature collected!')
    },
    [goBack, invoiceGuid, message, saveSignature, writeInvoiceEvent],
  )

  const { emailInvoicePDF, emailingPdf } = useSendInvoiceEmail(invoiceGuid)

  const isFinanceable = useIsInvoiceFinanceable(invoice.status, invoiceTotals)

  const isFullyPaid = useMemo(
    () => isInvoiceFullyPaid(invoiceTotals),
    [invoiceTotals],
  )

  const showCollectPaymentButton = useMemo(
    () => invoice.invoiceTerm !== 'AUTO' && !isFullyPaid,
    [invoice.invoiceTerm, isFullyPaid],
  )

  const { content, title, statusText, statusColorClassName } = useMemo(() => {
    if (signatureMode) {
      return {
        content: (
          <SignatureForm
            onCancel={goBack}
            onSubmit={onSignatureSubmit}
            loading={isSavingSignature}
          />
        ),
        title: 'Collect Signature',
      }
    }

    let primaryCtaText = ''
    let primaryCtaOnClick = noOp
    const mp = invoice.maintenancePlanLink

    const viewPlanDetails = () => {
      mp &&
        navigate(
          CalculatePaths.maintenancePlanDetails({
            maintenancePlanGuid: mp.maintenancePlanGuid,
          }),
        )
    }

    if (mp) {
      primaryCtaOnClick = viewPlanDetails
      primaryCtaText = 'View Plan Details'
    } else if (embedded) {
      primaryCtaText = 'Collect Payment'
      primaryCtaOnClick = onCollectPayment
    } else {
      primaryCtaText = 'Present to Customer'
      primaryCtaOnClick = onPresentToCustomer
    }

    let title = `Invoice #${invoice.displayId}`

    if (mp) {
      if (isMobile) {
        title = `Maint. ${title}`
      } else {
        title = `Maint. Plan ${title}`
      }
    }

    return {
      title,
      content: (
        <>
          <OnsitePageSection title="Invoice" hideBottomBorder>
            <InvoiceContent />
          </OnsitePageSection>
          {!embedded && (
            <SignatureSection
              hasSignature={!!invoice.signatureFileUrl}
              onSignatureClick={openSignatureMode}
            />
          )}
          {history.length && history.length > 0 ? (
            <InvoiceHistorySection
              history={history}
              payments={existingPayments ?? []}
            />
          ) : null}
          {isQuickbooksOnlineEnabled && (
            <QboSection
              isStale={isQboStale}
              qboSyncedAt={qboSyncedAt}
              issuedAt={invoice.issuedAt}
              isFullyPaid={isFullyPaid}
              lastPaymentAt={
                // The payment records are sorted by occurredAt ascending in the GQL query
                invoice.paymentRecords[
                  (invoice.paymentRecords?.length ?? 1) - 1
                ]?.occurredAt
              }
            />
          )}
          {isQuickbooksDesktopEnabled && (
            <InvoiceAccountingSection
              accountingIntegrationType="QBD"
              invoiceGuid={invoiceGuid}
              invoiceIssuedAt={invoice.issuedAt}
              isFullyPaid={isFullyPaid}
              isStale={invoice?.accountingStaleInfo?.isStale ?? true}
              lastSyncedAt={invoice?.accountingStaleInfo?.syncedAt}
              lastPaymentAt={invoice?.accountingStaleInfo?.latestPaymentAt}
            />
          )}
          {/* No title and no content means I just get the fat horizontal line */}
          <OnsitePageSection />

          <TaxAndDynamicSection
            taxRate={taxRate}
            dynamicPricingType={invoice.dynamicPricingType}
          />

          {isFinanceable && (
            <FinancingSection
              accountGuid={accountGuid}
              companyName={companyName}
              jobGuid={jobGuid}
              invoiceGuid={invoiceGuid}
            />
          )}
          {isFinanceable && (
            <LoanPromoShortDisclaimer
              className={isMobile ? 'px-4' : ''}
              amountUsc={invoiceTotals.dueUsc}
            />
          )}

          {isMobile ? (
            <Switch value={invoice.status}>
              {{
                OPEN: () => (
                  <MobileActionBar
                    primaryCtaText={primaryCtaText}
                    primaryCtaOnClick={primaryCtaOnClick}
                  >
                    {!embedded && showCollectPaymentButton && (
                      <MobileActionBarButton
                        icon={faSackDollar}
                        onClick={onCollectPayment}
                        name="invoice-payment-button"
                      >
                        Payment
                      </MobileActionBarButton>
                    )}
                    <MobileActionBarButton
                      icon={faEdit}
                      name="invoice-edit-button"
                      onClick={openEditMode}
                    >
                      Edit
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEnvelope}
                      disabled={emailingPdf}
                      loading={emailingPdf}
                      onClick={emailInvoicePDF}
                    >
                      Send Copy
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEllipsis}
                      onClick={openActionsModal}
                      name="actions-button"
                    >
                      More
                    </MobileActionBarButton>
                  </MobileActionBar>
                ),
                PAID: () => (
                  <MobileActionBar>
                    <MobileActionBarButton
                      icon={faEnvelope}
                      onClick={emailInvoicePDF}
                    >
                      Send Copy
                    </MobileActionBarButton>
                    <MobileActionBarButton icon={faEdit} onClick={openEditMode}>
                      Revise
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEllipsis}
                      onClick={openActionsModal}
                      name="actions-button"
                    >
                      More
                    </MobileActionBarButton>
                  </MobileActionBar>
                ),
                VOIDED: () => (
                  <MobileActionBar>
                    <MobileActionBarButton
                      icon={faEnvelope}
                      onClick={emailInvoicePDF}
                    >
                      Send Copy
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEllipsis}
                      onClick={openActionsModal}
                      name="actions-button"
                    >
                      More
                    </MobileActionBarButton>
                  </MobileActionBar>
                ),
                UNCOLLECTABLE: () => (
                  <MobileActionBar>
                    {showCollectPaymentButton && (
                      <MobileActionBarButton
                        icon={faSackDollar}
                        onClick={onCollectPayment}
                      >
                        Payment
                      </MobileActionBarButton>
                    )}
                    <MobileActionBarButton
                      icon={faEdit}
                      name="invoice-edit-button"
                      onClick={openEditMode}
                    >
                      Revise
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEnvelope}
                      disabled={emailingPdf}
                      loading={emailingPdf}
                      onClick={emailInvoicePDF}
                    >
                      Send Copy
                    </MobileActionBarButton>
                    <MobileActionBarButton
                      icon={faEllipsis}
                      onClick={openActionsModal}
                      name="actions-button"
                    >
                      More
                    </MobileActionBarButton>
                  </MobileActionBar>
                ),
                default: () => null,
              }}
            </Switch>
          ) : (
            <ActionBar>
              <Button
                size="large"
                className="min-w-[40px]"
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={openActionsModal}
                data-testid="actions-button"
              />
              <Switch value={invoice.status}>
                {{
                  OPEN: () => (
                    <>
                      <Button
                        className="flex-1"
                        size="large"
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        data-testid="invoice-edit-button"
                        onClick={openEditMode}
                      >
                        Edit
                      </Button>
                      {!embedded ? (
                        <>
                          {showCollectPaymentButton && (
                            <Button
                              className="flex-1"
                              size="large"
                              icon={<FontAwesomeIcon icon={faSackDollar} />}
                              onClick={onCollectPayment}
                              data-testid="invoice-payment-button"
                            >
                              Collect Payment
                            </Button>
                          )}
                          <RightArrowButton
                            className="flex-1"
                            onClick={primaryCtaOnClick}
                          >
                            {primaryCtaText}
                          </RightArrowButton>
                        </>
                      ) : showCollectPaymentButton ? (
                        <RightArrowButton
                          className="flex-1"
                          onClick={onCollectPayment}
                          data-testid="invoice-payment-button"
                        >
                          Collect Payment
                        </RightArrowButton>
                      ) : mp ? (
                        <RightArrowButton
                          className="flex-1"
                          onClick={viewPlanDetails}
                        >
                          View Plan Details
                        </RightArrowButton>
                      ) : null}
                    </>
                  ),
                  PAID: () => (
                    <>
                      <Button
                        className="flex-1"
                        size="large"
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        data-testid="invoice-edit-button"
                        onClick={openEditMode}
                      >
                        Revise
                      </Button>
                      <Button
                        size="large"
                        className={classNames(
                          'flex-1',
                          emailingPdf
                            ? 'pointer-events-none cursor-wait opacity-50'
                            : '',
                        )}
                        icon={
                          emailingPdf ? (
                            <BaseLoadingSpinner size={4} />
                          ) : (
                            <FontAwesomeIcon icon={faEnvelope} />
                          )
                        }
                        data-testid="invoice-send-button"
                        onClick={emailInvoicePDF}
                      >
                        Send Copy
                      </Button>
                    </>
                  ),
                  VOIDED: () => (
                    <Button
                      size="large"
                      className={classNames(
                        'flex-1',
                        emailingPdf
                          ? 'pointer-events-none cursor-wait opacity-50'
                          : '',
                      )}
                      icon={
                        emailingPdf ? (
                          <BaseLoadingSpinner size={4} />
                        ) : (
                          <FontAwesomeIcon icon={faEnvelope} />
                        )
                      }
                      data-testid="invoice-send-button"
                      onClick={emailInvoicePDF}
                    >
                      Send Copy
                    </Button>
                  ),
                  UNCOLLECTABLE: () => (
                    <>
                      <Button
                        size="large"
                        className="flex-1"
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        data-testid="invoice-edit-button"
                        onClick={openEditMode}
                      >
                        Revise
                      </Button>
                      <Button
                        size="large"
                        className={classNames(
                          'flex-1',
                          emailingPdf
                            ? 'pointer-events-none cursor-wait opacity-50'
                            : '',
                        )}
                        icon={
                          emailingPdf ? (
                            <BaseLoadingSpinner size={4} />
                          ) : (
                            <FontAwesomeIcon icon={faEnvelope} />
                          )
                        }
                        data-testid="invoice-send-button"
                        onClick={emailInvoicePDF}
                      >
                        Send Copy
                      </Button>
                      {showCollectPaymentButton && (
                        <Button
                          className="flex-1"
                          size="large"
                          icon={<FontAwesomeIcon icon={faSackDollar} />}
                          onClick={onCollectPayment}
                          data-testid="invoice-payment-button"
                        >
                          Collect Payment
                        </Button>
                      )}
                    </>
                  ),
                  default: () => null,
                }}
              </Switch>
            </ActionBar>
          )}
          {presentMode && (
            <OnsiteModal drawer={false} size="full" className="pb-0">
              <OnsiteModalContent onClose={goBack} unpadBottom>
                <div
                  data-testid="present-modal-content"
                  className={classNames({ 'mx-[-16px]': isMobile })}
                >
                  <PresentableInvoiceContent
                    hasSignature={!!invoice.signatureFileUrl}
                    onSignatureClick={openSignatureMode}
                    onCollectPayment={
                      invoice.status !== 'PAID' ? onCollectPayment : undefined
                    }
                  />
                </div>
              </OnsiteModalContent>
            </OnsiteModal>
          )}
        </>
      ),
      statusText: INVOICE_STATUS_DISPLAY_INFO[invoice.status].label,
      statusColorClassName:
        INVOICE_STATUS_DISPLAY_INFO[invoice.status].colorClassName,
    }
  }, [
    signatureMode,
    invoice.maintenancePlanLink,
    invoice.displayId,
    invoice.signatureFileUrl,
    invoice.issuedAt,
    invoice.paymentRecords,
    invoice.dynamicPricingType,
    invoice.status,
    embedded,
    openSignatureMode,
    history,
    existingPayments,
    isQuickbooksOnlineEnabled,
    isQboStale,
    qboSyncedAt,
    isFullyPaid,
    taxRate,
    isFinanceable,
    accountGuid,
    companyName,
    jobGuid,
    invoiceGuid,
    isMobile,
    invoiceTotals.dueUsc,
    openActionsModal,
    presentMode,
    goBack,
    onCollectPayment,
    onSignatureSubmit,
    isSavingSignature,
    navigate,
    onPresentToCustomer,
    showCollectPaymentButton,
    openEditMode,
    emailingPdf,
    emailInvoicePDF,
    isQuickbooksDesktopEnabled,
    invoice.accountingStaleInfo?.isStale,
    invoice.accountingStaleInfo?.syncedAt,
    invoice.accountingStaleInfo?.latestPaymentAt,
  ])

  const invoiceInfo: InvoiceInfoContextType = useMemo(() => {
    const info: InvoiceInfoContextType = {
      displayId: invoice.displayId,
      issuedAt: invoice.issuedAt,
      createdAt: invoice.createdAt,
      jobLink: invoice?.jobLink
        ? {
            jobGuid: invoice.jobLink.jobGuid,
            jobType: invoice.jobLink.job.jobType.name,
            jobDisplayId: invoice.jobLink.job.displayId,
            jobCreatedAt: invoice.jobLink.job.createdAt,
          }
        : undefined,
      estimateLink: invoice?.estimateLink
        ? {
            estimateGuid: invoice.estimateLink.estimate.estimateGuid,
            displayId: invoice.estimateLink.estimate.displayId,
            createdAt: invoice.estimateLink.estimate.createdAt,
            acceptedAt: invoice.estimateLink.estimate.acceptedAt,
          }
        : undefined,
      payments: paymentRecordsToInvoicePayments(invoice.paymentRecords),
      maintenancePlanLink:
        invoice?.maintenancePlanLink &&
        invoice.maintenancePlanLink?.maintenancePlan
          ? {
              maintenancePlanGuid:
                invoice.maintenancePlanLink.maintenancePlanGuid,
              activatedAt:
                invoice.maintenancePlanLink.maintenancePlan.activatedAt,
              createdAt: invoice.maintenancePlanLink.maintenancePlan.createdAt,
              planName:
                invoice.maintenancePlanLink.maintenancePlan
                  .maintenancePlanDefinition?.marketingInfo?.name,
            }
          : undefined,
      appointmentLink: getAppointmentLinkFromInvoice(invoice),
      accountGuid: invoice.accountGuid,
      contactFullName:
        invoice.jobLink?.job.pointOfContact.fullName ??
        invoice.account.accountContacts[0]?.contact.fullName ??
        'Account',
      accountCreatedAt: invoice.account.createdAt,
    }

    return info
  }, [invoice])

  return (
    <InvoiceDataContext.Provider
      value={{
        messageHtml: invoice.messageHtml ?? '',
        taxRate,
        info,
        lineItems,
        discounts,
        invoiceTotals,
        dynamicPricingType: invoice.dynamicPricingType,
      }}
    >
      <InvoiceInfoContext.Provider
        value={presentMode ? undefined : invoiceInfo}
      >
        <InvoicePageContainer
          containerRef={pageContainerRef}
          title={title}
          statusText={statusText}
          statusColorClassName={statusColorClassName}
        >
          {content}
        </InvoicePageContainer>
        {paymentWorkflowWizard}
        {processingPaymentsModal}
        {actionsModalOpen && (
          <InvoiceActionsModal
            invoice={invoice}
            onCancel={closeActionsModal}
            markAsOptions={
              getInvoiceV2StatusDisplayInfo(invoice.status).markAsOptions
            }
            hiddenPaymentOptions={hiddenPaymentWorkflowOptions}
            disabledOptions={
              invoice.maintenancePlanLink?.maintenancePlan
                ? paymentWorkflowDisabledOptions
                : [{ option: 'Payment Link' }]
            }
          />
        )}
      </InvoiceInfoContext.Provider>
    </InvoiceDataContext.Provider>
  )
})

export const InvoiceOverviewPage = React.memo(() => {
  const invoiceGuid = bzExpect(
    useParams().invoiceGuid,
    'invoiceGuid',
    'InvoiceGuid is required',
  )
  const tzId = useExpectedCompanyTimeZoneId()

  const [editMode] = useQueryParamFlag('edit')

  const { hasLoaded: relevantDataHasLoaded, ...relevantData } =
    useRelevantInvoiceData()

  const [{ data: invoiceData }] = useSubscription({
    query: INVOICE_DATA_SUBSCRIPTION,
    variables: { invoiceGuid },
  })

  const isLoading = !invoiceData || !relevantDataHasLoaded

  if (isLoading) {
    return <OnsitePageLoader />
  }

  if (!invoiceData.invoicesByPk || !relevantData) {
    return (
      <OnsitePageContainer title="Error">
        <div className="text-center">Invoice not found</div>
      </OnsitePageContainer>
    )
  }

  const invoice = invoiceData.invoicesByPk

  return (
    <InvoiceContext.Provider
      value={{
        invoiceGuid,
        tzId,
        ...invoice,
        ...relevantData,
        jobGuid: invoice.jobLink?.jobGuid,
        maintenancePlanGuid: invoice.maintenancePlanLink?.maintenancePlanGuid,
      }}
    >
      {editMode || invoice.status === 'DRAFT' ? (
        <EditView invoice={invoice} />
      ) : (
        <InvoiceOverview invoice={invoice} />
      )}
    </InvoiceContext.Provider>
  )
})

export default InvoiceOverviewPage
